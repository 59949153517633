.nav_bar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    background: #100C1D;
    width: 100%;
    position: fixed;
    height: 100px;
    z-index: 10;
}

.nav_bar > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav_bar .left_section {
    cursor: pointer;
}

.nav_bar .left_section img {
    width: 270px;
}

.nav_bar .right_section {
    position: relative;
    display: flex;
    align-items: center;
}

.nav_bar .connect_button {

}

.nav_bar .connect_button button {
    color: #FFF;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    text-transform: capitalize;
    background: unset;
    max-width: unset;
    box-shadow: unset;
}

.nav_bar .connected_button.mobile {
    display: none;
}

.nav_bar .connected_button button {

}

.nav_bar .connected_button button > div {

}

.nav_bar .connected_button button > div .name {
    color: #C4B8EA;
    text-align: right;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.nav_bar .connected_button button > div .email {
    color: #8F7DBC;
    text-align: right;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.nav_bar .connected_button button > svg {
    margin-left: 14px;
}

.nav_bar .connected_button.hide_popover button > svg {
    transform: rotate(180deg);
}

/* profile_popover */
.profile_popover > div:nth-child(3) {
    background: unset;
    box-shadow: unset;
    margin-top: 20px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

 .popover_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    background: #1B1530;
    border-radius: 10px;
    padding: 10px 0;
}

.popover_section button {
    color: #C4B8EA;
    text-align: right;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;

    /*margin-right: 10px;*/
    text-transform: capitalize;
    box-shadow: unset;
}

.popover_section button > span {
    color: #8F7DBC;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-left: 6px;
    margin-top: 3px;
}

.popover_section .logout {
    color: #FA655C;
    text-align: right;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin-right: 0;
}

.nav_bar .buy_tickets {
    background: #5A36A7;
    border-radius: 5px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    margin: 0 20px 0 30px;
    height: 40px;
    border: 1px solid #815CD1;
    padding: 10px 20px;
}

.nav_bar .buy_tickets.ticket_types {
    text-transform: unset;
}

.nav_bar .buy_tickets:hover {
    background: rgba(90, 54, 167, 0.79);
}

.nav_bar .toggle_button,
.nav_bar .right_section .back_button {
    display: none;
}

.nav_bar .toggle_button svg {
    fill: #FFFFFF;
}

@media (min-width: 1200px) {
    .nav_bar > div {
        max-width: 1200px;
        position: relative;
    }
}

@media (min-width: 1400px) {
    .profile_popover > div:nth-child(3) {
        left: 1100px !important;
    }
}

@media (max-width: 800px) {
    .nav_bar .right_section.show .back_button {
        display: flex;
    }

    .nav_bar .right_section {
        display: none;
        animation-duration: .5s;
        animation-fill-mode: both;
    }

    .nav_bar .right_section.show {
        display: flex;
        flex-direction: column;
        padding: 20px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(14, 14, 14, 0.2);
        backdrop-filter: blur(40px);
        animation-name: fadeInDown;
    }

    .nav_bar .toggle_button {
        display: flex;
        min-width: auto;
    }

    .nav_bar .toggle_button > span:first-child {
        display: flex;
    }

    .nav_bar {
        padding: 20px;
    }

    .right_section .back_button {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%;
        justify-content: flex-end;
        padding: 0 10px 0 0;
    }

    .right_section .back_button .icon {
        width: 30px;
    }

    .nav_bar .buy_tickets {
        margin: 20px 0;
    }

    .nav_bar .connected_button.mobile {
        display: unset;
    }

    .nav_bar .connected_button.web {
        display: none;
    }

    .profile_popover > div:nth-child(3) {
        left: 0 !important;
    }
}

@media (max-width: 560px) {
    .nav_bar .left_section img {
        width: 200px;
    }
}

