.dialog.eligibility_dialog > div:nth-child(3) > div {
    max-width: 500px;
    width: 100%;
    border-radius: 12px;
    background: #12111E;
    margin: unset;
    padding: 32px;
}

.dialog.eligibility_dialog > div .dialog_content {
    padding: 40px;
}

.eligibility_dialog .connect_dialog_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 35px 0;
    padding: 0 20px;
}

.eligibility_dialog h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
    margin: unset;
}

.eligibility_dialog .connect_dialog_content .spinner {
    height: 300px;
}

.eligibility_dialog .connect_dialog_content .spinner > img {
    height: 100%;
}

.eligibility_dialog .connect_dialog_content .success {
    color: #92FF88;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    padding: 10px 20px;
    margin: 30px auto;
    border-radius: 9999px;
    border: 1px solid #92FF88;
    background: rgba(65, 122, 38, 0.20);
    text-transform: capitalize;
    width: max-content;
}

.eligibility_dialog .connect_dialog_content .failed {
    color: #FAA37E;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 23.4px */
    padding: 30px 0;
    padding-bottom: 0;
}

.eligibility_dialog .connect_dialog_content .not_eligible {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 10px;
    margin: 10px auto 30px auto;
    width: 80%;
}

.eligibility_dialog .connect_dialog_content .not_eligible span {
    color: #66607D;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
}

.eligibility_dialog .connect_dialog_content .buy_now {
    border-radius: 4px;
    border: 1px solid #815CD1;
    background: #5A36A7;
    width: 100%;
    display: flex;
    padding: 14px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: unset;
}

@media (max-width: 768px) {
    .dialog.connect_dialog > div:nth-child(3) > div {
        max-width: 90%;
        width: 90%;
        min-width: 90%;
        margin: 10px;
    }
}
