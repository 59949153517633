.sponsors_registration {
    padding: 100px 40px 40px;
    max-width: 960px;
    margin: auto;
}

.sponsors_registration .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    cursor: pointer;
}

.sponsors_registration .header > img {
    margin-right: 10px;
}

.sponsors_registration .header > p {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.sponsors_registration .fields_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.sponsors_registration .fields_section > div {
    width: 49%;
    margin-bottom: 16px;
}

.sponsors_registration .fields_section .text_field {

}

.sponsors_registration .fields_section .text_field > div {
    border-radius: 4px;
    border: 1px solid #50437B;
    background: #28213F;
    text-align: left;
}

.sponsors_registration .fields_section .text_field.select_field > div {
    color: #C4B8EA;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 16px 18px;
}

.sponsors_registration .fields_section .text_field input {
    color: #C4B8EA;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 30px;
}

.sponsors_registration .fields_section .text_field fieldset {
    border: unset;
}

.sponsors_registration .fields_section .text_field textarea {
    padding: 0;
    color: #C4B8EA;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sponsors_registration .fields_section .select_field svg {
    fill: #C4B8EA;
}

.sponsors_registration .fields_section .select_field ::placeholder {
    color: #C4B8EA;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sponsors_registration .fields_section .select_field {

}

.sponsors_registration .upload_logo {
    border-radius: 4px;
    border: 1px dashed #50437B;
    background: rgba(38, 30, 64, 0.50);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sponsors_registration .upload_logo > p {
    color: #C4B8EA;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sponsors_registration .upload_logo .drag_drop {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.sponsors_registration .upload_logo .drag_drop > p {
    color: #C4B8EA;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 10px;
}

.sponsors_registration .upload_logo .drag_drop > button {
    border-radius: 4px;
    border: 1px solid #815CD1;
    background: #5A36A7;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    padding: 8px 14px;
}

.sponsors_registration .actions {

}

.sponsors_registration .actions button {
    border-radius: 4px;
    border: 1px solid #815CD1;
    background: #5A36A7;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 12px 20px;
    margin-top: 20px;
    width: 100%;
    text-transform: capitalize;
}
