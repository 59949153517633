@font-face {
    font-family: 'Mikado Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Mikado Bold'), url('MikadoBold.woff') format('woff');
}

@font-face {
    font-family: 'Aeion Mono';
    font-style: normal;
    font-weight: 400;
    src: local('Aeion Mono Test Regular'), url('AeionMonoVariableTest-Regular.woff') format('woff');
}


@font-face {
    font-family: 'Aeion Mono';
    font-style: normal;
    font-weight: 200;
    src: local('Aeion Mono Test Extra Light'), url('AeionMonoVariableTest-ExtraLight.woff') format('woff');
}


@font-face {
    font-family: 'Aeion Mono';
    font-style: normal;
    font-weight: 300;
    src: local('Aeion Mono Test Light'), url('AeionMonoVariableTest-Light.woff') format('woff');
}


@font-face {
    font-family: 'Aeion Mono';
    font-style: normal;
    font-weight: normal;
    src: local('Aeion Mono Test Semi Light'), url('AeionMonoVariableTest-SemiLight.woff') format('woff');
}


@font-face {
    font-family: 'Aeion Mono';
    font-style: normal;
    font-weight: 500;
    src: local('Aeion Mono Test Semi Bold'), url('AeionMonoVariableTest-SemiBold.woff') format('woff');
}


@font-face {
    font-family: 'Aeion Mono';
    font-style: normal;
    font-weight: 700;
    src: local('Aeion Mono Test Bold'), url('AeionMonoVariableTest-Bold.woff') format('woff');
}


@font-face {
    font-family: 'Aeion Mono';
    font-style: normal;
    font-weight: 900;
    src: local('Aeion Mono Test Extra Bold'), url('AeionMonoVariableTest-ExtraBold.woff') format('woff');
}