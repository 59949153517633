.sign_in_dialog div[role='dialog'] {
    background: unset;
}

.sign_in {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign_in > form {
    width: 600px;
    background: #100c1d;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 40px;
}

.sign_in h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
}

.sign_in .email_field {
    margin: 40px 0;
}

.sign_in .email_field .text_field {
    width: 60%;
}

.sign_in .email_field .text_field > div {
    border-radius: 4px;
    border: 1px solid #50437B;
    background: #28213F;
}

.sign_in .email_field .text_field p {
    text-align: center;
    margin: unset;
}

.sign_in .email_field input {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #C4B8EA;
}

.sign_in .email_field input::placeholder {
    opacity: 0.4;
}

.sign_in .email_field > p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.011em;
    color: #AAAAAA;
    margin-top: 20px;
}

.sign_in .sign_in_action button {
    border-radius: 4px;
    border: 1px solid #815CD1;
    background: #5A36A7;
    color: #FFF;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    text-transform: capitalize;
    padding: 4px 10px;
}

.sign_in .otp_content {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #AAAAAA;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign_in .otp_content span {
    color: #8F7DBC;
    font-size: 16px;
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 300;
    letter-spacing: 3.2px;
    text-transform: uppercase;
    margin: 0 5px;
}

.sign_in .otp_content p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    text-decoration-line: underline;
    color: #00A3F2;
    cursor: pointer;
    margin: 0 5px;
}

.sign_in .otp_content p.disable {
    cursor: no-drop;
    color: #ffffffe6;
    text-decoration: unset;
}

.sign_in .sign_in_action button:disabled {
    color: #ffffff80;
}

.sign_in .otp_field > p {
    margin-bottom: 15px;
    margin-top: 0;
}

.sign_in .otp_field > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign_in .otp_field > div > div {
    background: #1A1F25;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 40px;
    width: 40px;
    margin-right: 10px;
}

.sign_in .otp_field > div > div:last-child {
    margin-right: 0;
}

.sign_in .otp_field input {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    width: 40px !important;
    height: 40px;
    border-radius: 6px;
    border: unset;
    background: #28213F;
}

.sign_in .otp_field > div > div:focus-visible input {
    border-radius: 6px;
    border: 1px solid #BE9FFF;
    background: #28213F;
}

@media (max-width: 600px) {
    .sign_in .email_field .text_field {
        width: 100%;
    }
}

@media (max-width: 460px) {
    .sign_in > form {
        padding: 40px 20px;
    }

    .sign_in .email_field {

    }

    .sign_in .email_field .text_field {
        width: 100%;
    }
}
