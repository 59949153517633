@import url('./assets/fonts/style.css');

html, body, #root, .app, .of_community, .main_content, .content_div {
    min-height: 100% !important;
    height: 100%;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    text-align: center;
    color: #000;
    background: #100C1D;
}

p {
    margin: unset;
}

.hash_text {
    font-family: 'Space Mono', monospace;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    font-feature-settings: 'zero' on;
    color: #FFFFFF;
    max-width: 100%;
    display: flex;
    align-items: center;
}

.hash_text .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: -1px;
    width: 80px;
}

.main_content {
    display: flex;
    flex-direction: column;
}

.content_div {
    /*padding: 40px;*/
    /*margin-top: 20px;*/
    flex: 1 0 auto;
}

/* Fade In*/
.fade-in-image {
    animation: fadeIn 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* scroll bar */
html::-webkit-scrollbar,
.scroll_bar::-webkit-scrollbar {
    width: 16px;
}

html::-webkit-scrollbar-thumb,
.scroll_bar::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 6px solid transparent;
    background-clip: content-box;
    background-color: #757575;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #28213f inset !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #C4B8EA !important;
    caret-color: #C4B8EA;
}
