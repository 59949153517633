.select_tickets_page {
    /* background: rgba(46, 53, 63, 0.6); */
    /* border-top: 1px solid #323C49; */
    backdrop-filter: blur(25px);
    /* border-radius: 0 0 10px 10px; */
    /* position: fixed; */
    width: 100% !important;
    height: max-content;
    z-index: 10;
    padding: 20px;
    top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 90px;
    margin-top: 60px;
}

.select_tickets_page .header {
    margin-bottom: 30px;
}

.select_tickets_page .header h2 {
    color: #E9E3FF;
    font-family: 'IBM Plex Mono', monospace;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 10px 0;
}

.select_tickets_page .header p {
    color: #8F7DBC;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 3.2px;
    text-transform: uppercase;
}

.select_tickets_page .header .section3 {
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -.011em;
    line-height: 150%;
    margin-bottom: 20px;
    margin-top: 15px;
    text-align: center;
}

.select_tickets_page .header .section3 > span {
    color: #8F7DBC;
    font-weight: 700;
}

.select_tickets_page .header .section4 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.select_tickets_page .header .section4 > div {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.011em;
    color: #FFFFFF;
    margin-right: 12px;
}

.select_tickets_page .header .section4 > div:last-child {
    margin-right: 0;
}

.select_tickets_page .header .section4 div p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.011em;
    color: #D1C5FA;
    background: #171324;
    border: 2px solid #50437B;
    border-radius: 6px;
    padding: 8px 16px;
}

.select_tickets_page .header .section4 div > span {
    margin-top: 5px;
}

.select_tickets_page .select_cards {
    display: flex;
    justify-content: center;
    gap: 14px;
    flex-wrap: wrap;
    align-items: stretch;
}

.select_tickets_page .select_cards .no_data {
    color: #8F7DBC;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 3.2px;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.select_tickets_page .select_cards .card_details {
    border-radius: 16px;
    border: 2px solid rgba(80, 67, 123, 0.20);
    background: rgba(16, 12, 29, 0.70);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(10px);
    padding: 10px 20px;
    padding-bottom: 20px;
    width: 300px;
    padding-top: 20px;
    cursor: pointer;
}

.select_tickets_page .select_cards .card_details:hover {
    border: 2px solid rgb(80 67 123);
    background: rgb(16 12 29);
}

.select_tickets_page .select_cards .card_details.disabled {
    cursor: no-drop;
}

.select_tickets_page .select_cards .card_details .spinner {
    width: 100%;
    height: 226px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto auto 30px;
}

.select_tickets_page .select_cards .card_details .spinner img {
    /*width: 100%;*/
    height: 100%;
    /* width: 170px !important;
    height: 200px !important;
    margin-bottom: -40px;
    animation: opacityAnimation 2s; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.select_tickets_page .select_cards .card_details .ticket_type {
    color: #8F7DBC;
    text-align: center;
    text-shadow: 0px 3.553px 3.553px rgba(0, 0, 0, 0.25);
    font-family: 'IBM Plex Mono', monospace;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 2.8px;
    text-transform: uppercase;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.select_tickets_page .select_cards .card_details .ticket_price {
    color: #C4B8EA;
    font-family: 'Inter', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    margin: 10px 0;
}

.select_tickets_page .select_cards .card_details .more_options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
    /* margin-bottom: 80px; */
}

.select_tickets_page .select_cards .card_details .more_options > p {
    color: #E6B2FF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.select_tickets_page .select_cards .card_details .more_options > p svg {
    fill: #E6B2FF;
    margin-right: 10px;
}

.select_tickets_page .select_cards .card_details .actions {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.select_tickets_page .select_cards .card_details .actions .sold_out {
    border-radius: 4px;
    border: 1px solid #A64D4D;
    background: #853838;

    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: inherit;
    width: -webkit-fill-available;
    padding: 10px;
    margin: 20px;
}

.select_tickets_page .select_cards .card_details .actions .coming_soon {
    border-radius: 4px;
    border: 1px solid #5E5188;
    background: #362B5A;

    color: #8478AC;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: inherit;
    width: -webkit-fill-available;
    padding: 10px;
    margin: 20px;
}

.select_tickets_page .select_cards .card_details .actions .buy_now {
    border-radius: 4px;
    border: 1px solid #815CD1;
    background: #5A36A7;
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: inherit;
    width: -webkit-fill-available;
    padding: 10px;
    margin: 20px;
    margin-bottom: 10px;
}

/*  newly added css */

.select_tickets_page .select_cards .card_details .loyalty_content {
    color: #F9B58F;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 10px;
}

.select_tickets_page .select_cards .card_details .margin_update {
    margin-bottom: 80px;
}

.select_tickets_page .select_cards .card_details .know_more {
    color: rgba(255, 255, 255, 0.50);
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 80px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    text-transform: inherit;
}

.select_tickets_page .select_cards .card_details .actions .tickets_left {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.select_tickets_page .select_cards .card_details .actions .tickets_left > p {
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    /* display: list-item; */
    text-transform: capitalize;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

.select_tickets_page .select_cards .card_details .actions .tickets_left > p span {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 10px;
    margin-right: 8px;
}

.select_tickets_page .select_cards .card_details .actions .tickets_left > p.green {
    color: #C1FF72;
}
.select_tickets_page .select_cards .card_details .actions .tickets_left > p.green span {
    background: #C1FF72;;
}
.select_tickets_page .select_cards .card_details .actions .tickets_left > p.yellow {
    color: #F9E63C;
}
.select_tickets_page .select_cards .card_details .actions .tickets_left > p.yellow span {
    background: #F9E63C;;
}
.select_tickets_page .select_cards .card_details .actions .tickets_left > p.red {
    color: #FF9283;
}
.select_tickets_page .select_cards .card_details .actions .tickets_left > p.red span {
    background: #FF9283;;
}
.select_tickets_page .select_cards .card_details .actions .tickets_left > p.gray {
    color: #888;
}
.select_tickets_page .select_cards .card_details .actions .tickets_left > p.gray span {
    background: #888;
}

.select_tickets_page .select_cards .card_details .actions .buy_now:disabled {
    border: 1px solid #392E4F;
    background: #1E1A28;
    color: rgba(255, 255, 255, 0.40);
}

@media (max-width: 425px) {
    .select_tickets_page .select_cards .card_details {
        width: 100%;
    }
}
